import "./bootstrap";
import "../scss/app.scss";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import { vuetify } from "./Plugins/Vuetify";
import { i18nVue } from "laravel-vue-i18n";
import AuthorizationForLaravelInertiaVue from "authorization-for-laravel-inertia-vue";
import VueApexCharts from "vue3-apexcharts";

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(vuetify)
            .use(i18nVue, {
                resolve: (lang) => {
                    const langs = import.meta.glob("../../lang/*.json", {
                        eager: true,
                    });
                    return langs[`../../lang/${lang}.json`].default;
                },
            })
            .use(AuthorizationForLaravelInertiaVue)
            .use(VueApexCharts)
            .mount(el);
    },
    progress: {
        color: "#f46e3b",
        showSpinner: true,
    },
});

/**
 *  Send Analytics data to Google Analytics in production mode
 */

import { router } from "@inertiajs/vue3";

if (
    process.env.NODE_ENV === "production" &&
    import.meta.env.VITE_ANALYTICS_MEASUREMENT_ID
) {
    router.on("navigate", (event) => {
        gtag("js", new Date());
        gtag("config", import.meta.env.VITE_ANALYTICS_MEASUREMENT_ID);
    });
}
