import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { en, ar } from "vuetify/locale";

export const vuetify = createVuetify({
    components,
    directives,
    ssr: true,
    theme: {
        defaultTheme: "light",
    },
    locale: {
        locale: "ar",
        // fallback: 'sv',
        messages: { en, ar }
    },
});
